import React from 'react';
import useClientContext from '@/context/ClientContext';
import styles from './styles.module.scss';
import PublicIcons from '@/components/basic/PublicIcon';
import Button from '@/controls/Button';
import cn from "@/libs/cn";

interface NotFoundProps {
  meta: any;
  icon: (props: any) => JSX.Element;
  title: string
  subtitle: string
  hideLogo?: boolean
}

export default function ErrorPageLayout(props: NotFoundProps) {
  const { meta, icon: Icon, title, subtitle, hideLogo = false } = props;
  const {  translate } = useClientContext();

  return (
    <section className={styles.ErrorPage} data-error={meta}>
      {
        !hideLogo && <PublicIcons name="mallprixLogoV3" objectFit="contain" width={204} height={48} />
      }
      <figure className={cn(styles.image, hideLogo && styles.withoutLogo)}>
        <Icon />
      </figure>
      <h1 className={styles.title}>{title}</h1>
      <h3 className={styles.subtitle}>{subtitle}</h3>

      <Button
        href="/"
        color="primary"
        type="link"
      >
        {/* TODO translate */}
        {"На головну" || translate("Return to home")}
      </Button>
    </section>
  );
}
